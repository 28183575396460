import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { VirtualMachine } from "modules/servers/Models";
import { InstalledApp } from "modules/apps/Models";
import ConfigureDomain from "modules/apps/components/ConfigureDomain";
import ResourceCardStatus from "modules/core/components/ResourceCardStatus";
import ResourceCardDetails from "modules/core/components/ResourceCardDetails";

/**
 * ResourceCard displays details for a virtual machine or application
 *
 * @param {Object} props - Component properties
 * @param {Object} props.resource - Resource information
 * @param {string} props.manage_path - Management page URL
 * @param {"app"|"vm"} props.resource_type - Type of resource
 */

function ResourceCard(props) {
  const {
    resource,
    actions,
    allowIPCopy,
    isClickable = true,
    manage_path,
    resource_type,
  } = props;

  return (
    <>
      <ToastContainer />
      <ConfigureDomain
        installed_app_id={resource.id}
        domain_connection_settings={resource.domain_connection_settings}
      />
      <div className="card shadow mt-3 text-dark" key={resource.id}>
        <div className="card-body">
          <ResourceCardStatus
            resource={resource}
            resource_type={resource_type}
            actions={actions}
          />

          <ResourceCardDetails
            resource={resource}
            resource_type={resource_type}
            allowIPCopy={allowIPCopy}
            isClickable={isClickable}
            manage_path={manage_path}
          />
        </div>
      </div>
    </>
  );
}

ResourceCard.propTypes = {
  resource: PropTypes.oneOfType([
    PropTypes.instanceOf(VirtualMachine),
    PropTypes.instanceOf(InstalledApp),
  ]).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  manage_path: PropTypes.string.isRequired,
  resource_type: PropTypes.oneOf(["app", "vm"]).isRequired,
};

export default ResourceCard;
