import _ from "lodash";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

/**
 * Renders status indicators for a resource
 *
 * @param {Object} props - Component properties
 * @param {Object} props.resource - Resource details
 * @param {string} props.resource_type - Type of resource
 * @param {Object} props.actions - Available actions for resource
 */

function ResourceCardStatus({ resource, resource_type, actions }) {
  const resource_state_map = {
    running: "success",
    deployed: "success",
    stopped: "danger",
    creating: "info",
    stopping: "warning",
    starting: "warning",
    off: "secondary",
    terminating: "warning",
    paused: "secondary",
    progressing: "info",
    payment_failed: "danger",
    provision_failed: "danger",
    waiting_for_payment: "info",
  };

  const resource_state_lower = resource.state.toLowerCase();
  const resource_state_badge_color =
    resource_state_map[resource_state_lower] ?? "secondary";

  let resourceStatusIndicator = (
    <span
      className={`badge text-bg-${resource_state_badge_color} align-self-start`}
    >
      {_.capitalize(resource_state_lower.replace(/_/g, " "))}
    </span>
  );

  let statusReasonIndicator = null;

  if (
    resource_type === "app" &&
    !["stopped", "deleted"].includes(resource.state_reason) &&
    !["payment_failed", "provision_failed", "waiting_for_payment"].includes(
      resource_state_lower,
    ) &&
    resource.workload_running_percentage < 100
  ) {
    resourceStatusIndicator = (
      <Progress
        type="circle"
        percent={resource.workload_running_percentage}
        width={40}
        strokeWidth={10}
      />
    );
  } else if (
    resource_state_lower !== "running" &&
    resource.state_reason !== "" &&
    resource.state_reason
  ) {
    statusReasonIndicator = (
      <span className="badge text-bg-info align-self-start ms-2">
        {" "}
        {_.startCase(resource.state_reason)}{" "}
      </span>
    );
  }

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex">
        {resourceStatusIndicator}
        {statusReasonIndicator}
      </div>
      <div className="align-self-end">{actions}</div>
    </div>
  );
}

export default ResourceCardStatus;
