import { useCallback } from "react";
import { Modal as BootstrapModal } from "bootstrap";
import NavigationLink from "modules/base/components/NavigationLink";
import CopyToClipboardButton from "modules/base/components/CopyToClipboardButton";
import ResourceCardHead from "modules/core/components/ResourceCardHead";
import PlanSubscription from "modules/servers/components/PlanSubscription";
import VirtualMachineClone from "modules/servers/components/VirtualMachineClone";
import OperatingSystemManage from "modules/servers/components/OperatingSystemManage";

/**
 * Displays detailed information about a resource
 *
 * @param {Object} props - Component properties
 * @param {Object} props.resource - Comprehensive resource information
 * @param {boolean} props.allowIPCopy - Permission to copy IP
 * @param {string} props.manage_path - Path to resource management page
 */

function ResourceCardDetails(props) {
  const { resource, resource_type, allowIPCopy, isClickable, manage_path } =
    props;
  const configureDomain = useCallback(() => {
    const configureDomainModal = BootstrapModal.getOrCreateInstance(
      document.getElementById(`configure-domain-${resource.id}`),
    );
    configureDomainModal.show();
  }, [resource.id]);

  const Version =
    resource_type === "app" ? (
      <p className="card-text fw-normal">
        <i className="bi bi-git" /> {resource.version_number}
      </p>
    ) : (
      <p className="card-text fw-normal">
        <i className="bi bi-disc" /> {resource.os_version}
      </p>
    );

  const Disk =
    resource_type === "app" ? null : (
      <>
        <i className="bi bi-dot" />
        <i className="bi bi-hdd" /> {resource.root_disk_size} GiB
      </>
    );

  let Hostname = resource.hostname;
  let changeHostnameButton = null;

  if (resource_type === "app") {
    const change_hostname_label = resource.has_custom_domain
      ? "Change domain"
      : "Add your own domain";

    changeHostnameButton = (
      <button
        type="button"
        className="btn btn-sm btn-outline-purple"
        onClick={configureDomain}
      >
        <i className="bi bi-globe" /> {change_hostname_label}
      </button>
    );

    const url = `https://${resource.hostname}`;
    Hostname = (
      <NavigationLink path={url} classes="text-primary" isExternal>
        {resource.hostname}
      </NavigationLink>
    );
  }

  const copyIPButton = allowIPCopy ? (
    <CopyToClipboardButton text={resource.ip_address} noun="IP address" />
  ) : null;

  return (
    <>
      <NavigationLink
        path={manage_path}
        classes="text-dark"
        isClickable={isClickable}
      >
        {[
          "paused",
          "running",
          "stopped",
          "stopping",
          "starting",
          "off",
          "terminating",
          "creating",
          "progressing",
          "deleted",
          "provision_failed",
        ].includes(resource.state.toLowerCase()) && (
          <ResourceCardHead Hostname={Hostname} resource={resource} />
        )}

        {changeHostnameButton}

        <h6 className="card-subtitle mb-2 text-muted">
          {resource.ip_address} {copyIPButton}
        </h6>

        {Version}

        <p className="card-text fw-normal">
          <i className="bi bi-cpu" /> {resource.cpu} Core
          <i className="bi bi-dot" />
          <i className="bi bi-memory" /> {resource.memory} GiB
          {Disk}
        </p>
      </NavigationLink>
      {[
        "paused",
        "running",
        "stopped",
        "stopping",
        "starting",
        "off",
        "terminating",
        "creating",
        "progressing",
        "deleted",
      ].includes(resource.state.toLowerCase()) && (
        <>
          <PlanSubscription resource={resource} resource_type={resource_type} />
          <VirtualMachineClone
            resource={resource}
            resource_type={resource_type}
          />
          <OperatingSystemManage vm={resource} resource_type={resource_type} />
        </>
      )}
    </>
  );
}

export default ResourceCardDetails;
