import { useCallback, useState } from "react";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import ServersApiActions from "modules/servers/ApiActions";
import LaunchVMButton from "modules/servers/components/LaunchVMButton";
import Pagination from "modules/servers/components/Pagination";
import VirtualMachinesFilter from "modules/servers/components/VirtualMachinesFilter";
import VirtualMachinesListCard from "modules/servers/components/VirtualMachinesListCard";

import {
  PATH_DEPLOY_VM,
  PATH_VIRTUAL_MACHINES,
} from "modules/servers/Constants";

/**
 * VirtualMachinesList component
 * @returns {JSX.Element}
 * @constructor
 */
function VirtualMachinesList() {
  const serversApiActions = new ServersApiActions();
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const limit = 5;

  let {
    vms: virtualMachines,
    count,
    error,
    isLoading,
  } = serversApiActions.getVirtualMachines(limit, page, search, filters);

  const handleFiltersChange = useCallback((filtersOptions) => {
    setPage(1);
    setFilters(filtersOptions);
  }, []);

  const handleSearchChange = useCallback(
    (value) => {
      if (value !== search) {
        setPage(1);
      }
      setSearch(value);
    },
    [search, setPage],
  );

  const updatedData = serversApiActions.getVirtualMachinesWithState(
    limit,
    page,
    search,
    filters,
  );

  if (!updatedData.isLoading) {
    virtualMachines = updatedData.vms;
    count = updatedData.count;
    error = updatedData.error;
    isLoading = updatedData.isLoading;
  }

  const vmDeployPath = `${PATH_VIRTUAL_MACHINES}/${PATH_DEPLOY_VM}`;

  const showPagination = count > limit;

  return (
    <>
      <NetworkMessageDisplay error={error} />
      <div className="row">
        <div className="col-12 col-md-8">
          <VirtualMachinesFilter
            filters={filters}
            search={search}
            handleSearchChange={handleSearchChange}
            handleFiltersChange={handleFiltersChange}
          />
        </div>
        <div className="col-12 col-md-4 mt-2 mt-md-0">
          <LaunchVMButton urlPath={vmDeployPath} description="Launch VM" />
        </div>
      </div>

      <div className="flex flex-col">
        <VirtualMachinesListCard
          isLoading={isLoading}
          virtualMachines={virtualMachines}
          vmDeployPath={vmDeployPath}
        />

        {showPagination && (
          <div className="text-center mt-3 mb-5">
            <Pagination
              setPage={setPage}
              pageSize={limit}
              pageIndex={page}
              count={count}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default VirtualMachinesList;
