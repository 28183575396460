import ReactCountryFlag from "react-country-flag";
import PropTypes from "prop-types";
import { VirtualMachine } from "modules/servers/Models";
import { InstalledApp } from "modules/apps/Models";

function ResourceCardHead(props) {
  const { resource, Hostname } = props;

  const resourceLocationFlag: Element = (
    <ReactCountryFlag
      countryCode={resource.location_country_code}
      className="ms-2"
      svg
      style={{
        width: "1.2em",
        height: "1.2em",
      }}
    />
  );
  return (
    <div>
      <div className="d-flex mt-3 mt-md-0">
        <p className="card-title h3 d-none d-md-block text-black">
          {resource.display_name ? resource.display_name : Hostname}
        </p>
        <p className="card-title fs-6 d-block d-md-none">
          {resource.display_name ? resource.display_name : Hostname}
        </p>
        {resourceLocationFlag}
      </div>

      {resource.display_name ? (
        <p>
          <i className="bi bi-link-45deg" /> {Hostname}
        </p>
      ) : null}
    </div>
  );
}
ResourceCardHead.propTypes = {
    resource: PropTypes.oneOfType([
      PropTypes.instanceOf(VirtualMachine),
      PropTypes.instanceOf(InstalledApp),
    ]).isRequired,
    Hostname: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]).isRequired,
  };

export default ResourceCardHead;
